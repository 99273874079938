import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
/* import Img from "gatsby-image" */
import { graphql, Link }  from 'gatsby'
import ScrollAnim from 'rc-scroll-anim'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'
import Div from '../components/Div'
import H4 from '../components/H4'
import H5 from '../components/H5'
import H6 from '../components/H6'
import Paragraph from '../components/Paragraph'

import { Container } from '../components/styles/Layout.styles'

import { 
  styledCloudServer,
  header,
  scrollTo
} from '../components/styles/Contrato.styles'

export const PageTemplate = ({
  data,
  ...rest
}) => {
  return (
    <Fragment>
      <section css={styledCloudServer}>
        <Container>
          <Row
            flexDirection={['column', 'row']}
            css={header}
          >
            <Column
              width={[1]}
            >
              <H4>Política de Qualidade e Meio Ambiente</H4>
            </Column>
          </Row>
          <Row
            justifyContent={'center'}
            flexDirection={['column', 'column', 'row']}
            pt={40}
            pb={40}
          >
            <Column
              mb={[30, 30, 0, 0]}
            >
              <Div
                css={scrollTo}
              >
                <H5>Política de Qualidade e Meio Ambiente</H5>
                <ScrollAnim.Link
                  to='proposito'
                  offsetTop='120'
                >
                  Propósito
                </ScrollAnim.Link>
                <ScrollAnim.Link
                  to='aplicacao'
                  offsetTop='120'
                >
                  Aplicação
                </ScrollAnim.Link>
                <Link
                  to='/contrato'
                >
                  Voltar para Contrato
                </Link>
              </Div>
            </Column>
            <Column>
              <Div
                maxWidth={['100%', '100%', 422]}
              >
                <Div id='proposito'>
                  <H6>1. Propósito</H6>
                  <Paragraph>A HostDime Brasil ao desenvolver esta política, define as diretrizes em relação à Gestão da Qualidade e Gestão Ambiental para assegurar o compromisso com o foco no cliente e na sua satisfação, a qualidade dos serviços prestados, o atendimento adequado aos requisitos aplicáveis, o compromisso com a redução dos impactos ambientais e a participação de todos na melhoria contínua, com o intuito de alcançar os seguintes aspectos para o crescimento do negócio, além da preocupação do negócio com os impactos ambientais no qual a Organização encontra-se inserida:</Paragraph>
                  <ul>
                    <li>&#9679; Conscientizar e reforçar o comprometimento com os princípios Ambientais e da Qualidade, com seus requisitos a serem atendidos;</li>
                    <li>&#9679; Aumentar a satisfação ao atendimento das necessidades das partes interessadas;</li>
                    <li>&#9679; Fazer cumprir as metas destinadas aos objetivos ambientais e de qualidade estabelecidos;</li>
                    <li>&#9679; Manter a qualidade dos serviços acordados com os clientes;</li>
                    <li>&#9679; Manter o compromisso com a proteção do meio ambiente, suas interações e impactos ambientais;</li>
                    <li>&#9679; Identificar riscos e promover melhorias alinhadas aos objetivos ambientais e de qualidade;</li>
                    <li>&#9679; Manter-se entre as melhores empresas para se trabalhar no mercado atual;</li>
                    <li>&#9679; Capacitar e desenvolver os colaboradores para execução das atividades;</li>
                    <li>&#9679; Manter a liderança atuante no empenho e no desenvolvimento do Sistema de Gestão Integrado;</li>
                    <li>&#9679; Contribuir para a qualidade das informações produzidas pela empresa;</li>
                    <li>&#9679; Atender aos requisitos legais e os requisitos definidos pela Organização.</li>
                  </ul>
                </Div>
                <Div id='aplicacao'>
                  <H6 style={{ marginTop: 24 }}>2. Aplicação</H6>
                  <Paragraph>Esta política, com seus requisitos e responsabilidades, aplica-se a todos os funcionários, terceiros e fornecedores participantes do Sistema de Gestão Integrado da Organização. </Paragraph>
                </Div>
              </Div>
            </Column>
          </Row>
        </Container>
      </section>
    </Fragment>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object,
  values: PropTypes.object
}

const StaticPage = (props) => {
  return (
    <Layout>
      <Seo title='Política de Qualidade' />
      <PageTemplate
        title='Política de Qualidade'
        {...props}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "cloud-server/cloud-server.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default (StaticPage)
